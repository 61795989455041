.rbt-input-multi.focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
  outline: 0;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-main,
.rbt-input-multi.form-control-lg .rbt-input-main,
.rbt-input-multi.form-control-sm .rbt-input-main {
  height: auto;
}

.rbt-token {
  margin: 1px 3px 2px 0;
}
